import { Container, Row, Col, Button, Image, Dropdown, Placeholder } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import ContentWrapper from 'components/global/ContentWrapper';
import Money from 'components/global/Money';
import Pagination from 'components/global/Pagination';
import filterImg from 'images/filter-alt.svg';
import confettiImg from 'images/confetti.svg';
import twoPeopleImg from 'images/twoPeople.svg';
import vendorsArrowImg from 'images/vendorsArrow.svg';
import CustomerDetailTabs from 'components/views/Customers/_components/CustomerDetailTabs';
import SortingArrow from 'components/views/Bills/_components/SortingArrow';
import Loadable from 'components/global/Loadable';
import { $customersForm } from 'components/views/CustomerAdd/CustomerAdd.helpers';
import { useEffectAsync } from 'utils/useEffectAsync';
import {
  fetchAndSetCustomers,
  fetchAndSetAutocomplete,
  handleSelectCustomer,
  handleSelectSorting,
} from 'components/views/Customers/_helpers/Customers.helpers';
import {
  $customersUi,
  $customers,
  $customersFilter,
  $customerDetail,
  $customerStatement,
  customersSignals,
} from 'components/views/Customers/_helpers/Customers.signals';
import CustomerCard from 'components/views/Customers/_components/CustomerCard';
import CustomerDetailModal from 'components/views/Customers/_components/CustomerDetailModal';
import CustomersSearch from 'components/views/Customers/_components/CustomersSearch';
import CustomersAutoComplete from 'components/views/Customers/_components/CustomersAutoComplete';
import PromptSyncModal from 'components/views/Customers/_components/PromptSyncModal';
import CustomerStatementSidePanel from 'components/views/Customers/_components/CustomerStatementSidePanel';
import { $syncing } from 'signals/Global.signals';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useCleanup from 'hooks/useCleanup';

const Customers = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();
  const { name, balance, latest_acct_provider_balance: acctProviderBalance, invoice_summary: invoiceStatement } = $customerDetail.value;
  const { page, perPage, search, selectedSorting } = $customersFilter.value;
  const { items, totalCount } = $customers.value;
  const { isStatementSidebarOpen } = $customerStatement.value;

  useCleanup(customersSignals);

  useEffect(() => {
    $customersFilter.update({ page: 1 });
  }, [selectedSorting]);
  useEffectAsync(fetchAndSetCustomers, [page, selectedSorting]);
  useEffectAsync(fetchAndSetAutocomplete, [search], 400);

  return (
    <ContentWrapper>
      <Container fluid className="px-lg-40">
        <Row>
          <Col xs={12} lg={5} className="left-panel-border pt-32 pt-lg-88 px-0">
            <Row className="mr-24 mb-md-32 pe-lg-24">
              <Col className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '40px' }} className="fw-400 mb-0">Customers</h1>
                <Button
                  variant="info"
                  className="mb-8 circle-30 d-flex align-items-center justify-content-center"
                  onClick={() => {
                    $customersForm.reset();
                    history.push('/customers/add');
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} width={12} />
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mb-md-24 pe-lg-24">
              <Col xs={11}>
                <div className="position-relative">
                  <CustomersSearch />
                  <CustomersAutoComplete />
                </div>
              </Col>
              <Col xs={1} className="ps-0">
                <div className="d-flex justify-content-end cursor-pointer">
                  <Dropdown>
                    <Button
                      variant="eye"
                      className="p-0 m-0 text-secondary h-100"
                    >
                      <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle p-0 m-0 h-100">
                        <Image height={16} src={filterImg} />
                      </Dropdown.Toggle>
                    </Button>
                    <div className="float-right">
                      <Dropdown.Menu align="start" className={`${!isBreakpointDown('md') && 'custom-nav-dropdown'} p-0`}>
                        <Dropdown.Header>Sort By</Dropdown.Header>
                        <Dropdown.Item onClick={() => handleSelectSorting('latest_acct_provider_balance')} className="rounded">
                          Open Balance <SortingArrow signal={$customersFilter} field="latest_acct_provider_balance" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectSorting('name')} className="rounded">
                          Name <SortingArrow signal={$customersFilter} field="name" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Loadable template="component" signal={$customers}>
              <>
                {items && !items?.length ? (
                  <Row className="mx-0 p-0">
                    <Image className="w-100 px-0" src={confettiImg} />
                  </Row>
                ) : (
                  <>
                    <div className="overflow-scroll-y pe-lg-24 ps-lg-16" style={{ height: '60vh' }}>
                      {items && items.map(customer => (
                        <div key={customer.id} id={customer.id} className="mx-0">
                          <CustomerCard
                            item={customer}
                            active={customer.id === $customerDetail.value.id}
                            onSelect={() => {
                              handleSelectCustomer({ customer, isBreakpointDown });
                              $customersUi.update({ openTab: 'details' });
                            }}
                            handleClickInvoices={() => {
                              handleSelectCustomer({ customer, isBreakpointDown });
                              $customersUi.update({ openTab: 'invoices' });
                            }}
                            handleClickProjects={() => {
                              handleSelectCustomer({ customer, isBreakpointDown });
                              $customersUi.update({ openTab: 'projects' });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Pagination
                      className="pe-24"
                      itemsPerPageAmount={perPage}
                      paginationMaxButtonAmount={5}
                      totalItemsCount={totalCount}
                      currentPageItemsCount={items?.length}
                      currentPage={page}
                      setCurrentPage={(value) => $customersFilter.update({ page: value })}
                    />
                  </>
                )}
              </>
            </Loadable>
          </Col>
          <CustomerDetailModal />
          <PromptSyncModal />
          <Col lg={7} className="ps-24 mt-40 d-none d-lg-block vh-100 overflow-y-auto">
            {name ? (
              <Loadable template="component" signal={$customerDetail}>
                <div>
                  <div className="mx-0 d-flex justify-content-end mt-48">
                    <Button
                      onClick={() => $customerStatement.update({ isStatementSidebarOpen: true })}
                      disabled={$syncing.value.isSyncing}
                      variant="outline-primary"
                      classNme="w-auto"
                      size="sm"
                    >
                      SHARE STATEMENT
                    </Button>
                    <div className="ms-16">
                      <Button
                        onClick={() => history.push(`/customers/statement/${$customerDetail.value.id}`)}
                        disabled={$syncing.value.isSyncing}
                        variant="primary"
                        classNme="w-auto"
                        size="sm"
                      >
                        VIEW STATEMENT
                      </Button>
                    </div>
                  </div>
                  <Row className="mx-0 mt-24">
                    <Col className="d-flex justify-content-between align-items-center px-0">
                      <h1>{name}</h1>
                    </Col>
                  </Row>

                  <Row
                    className="mx-0 px-56 py-32 mb-24 mt-24 mb-56 bg-gray-50"
                    style={{ borderRadius: '15px' }}
                  >
                    <Col>
                      <div>
                        <p className="lead fw-800 text-primary">Balance:</p>
                        {$syncing.value.isSyncing ? (
                          <Placeholder as="div" animation="glow" className="mb-0 w-100">
                            <Placeholder xs={12} as="h1" className="text-loadable" />
                          </Placeholder>
                        ) : (
                          <h1><Money amount={acctProviderBalance !== null ? acctProviderBalance : balance} /></h1>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <p className="lead fw-800 text-primary">Overdue:</p>
                        {$syncing.value.isSyncing ? (
                          <Placeholder as="div" animation="glow" className="mb-0 w-100">
                            <Placeholder xs={12} as="h1" className="text-loadable" />
                          </Placeholder>
                        ) : (
                          <h1>
                            <Money amount={invoiceStatement?.overdue_amount} />
                          </h1>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center">
                    <Col>
                      <CustomerDetailTabs />
                    </Col>
                  </Row>
                </div>
              </Loadable>
            ) : (
              <>
                <Image src={vendorsArrowImg} height="150" />
                <h2 className="text-primary fw-700 text-center">
                  Let&apos;s get some customers added so that you can get paid!
                </h2>
                <Image src={twoPeopleImg} className="h-auto w-75 mt-24 d-flex mx-auto" />
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isStatementSidebarOpen && (
        <CustomerStatementSidePanel />
      )}
    </ContentWrapper>
  );
};

export default Customers;
