/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form } from 'react-bootstrap';
import { useRef } from 'react';

const DateRangePicker = ({
  startName,
  endName,
  startValue,
  endValue,
  onChange,
}) => {
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);

  const handleStartClick = () => {
    if (startInputRef.current) {
      startInputRef.current.showPicker?.(); // Open the picker explicitly on user click
    }
  };

  const handleEndClick = () => {
    if (endInputRef.current) {
      endInputRef.current.showPicker?.(); // Open the picker explicitly on user click
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    onChange({
      name: startName,
      value: newStartDate,
    });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    onChange({
      name: endName,
      value: newEndDate,
    });
  };

  return (
    <div className="d-flex p-0 border border-primary rounded">
      <div
        className="w-100"
        onClick={handleStartClick}
        style={{ cursor: 'pointer' }}
      >
        <Form.Control
          ref={startInputRef}
          name={startName}
          className="w-100 border-0 rounded not-form-control"
          type="date"
          placeholder="Start Date"
          value={startValue}
          onChange={handleStartDateChange}
          style={{ paddingTop: '6px', paddingBottom: '5px', pointerEvents: 'none' }}
        />
      </div>
      <div
        className="w-100"
        onClick={handleEndClick}
        style={{ cursor: 'pointer' }}
      >
        <Form.Control
          ref={endInputRef}
          name={endName}
          className="w-100 border-0 rounded not-form-control"
          type="date"
          placeholder="End Date"
          value={endValue}
          onChange={handleEndDateChange}
          style={{ paddingTop: '6px', paddingBottom: '5px', pointerEvents: 'none' }}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
