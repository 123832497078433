/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import loginLogo from 'images/loginLogo.svg';
import { $global, $loader } from 'signals/Global.signals';
import ComponentLoader from 'components/global/ComponentLoader';
import { handleLogout } from 'components/views/Auth/_shared/Auth.helpers';
import { $onboarding } from 'components/views/Onboarding/Onboarding.helpers';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';

const AuthLayout = ({ children, header }) => (
  <Container fluid className="min-vh-100 px-0 w-100" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
    {header && header()}
    <ThemeImage name="login_background" className="d-none d-lg-block position-absolute bottom-0 w-100 object-fit-cover" alt="Two People Reading" />
    {$global.value.isSignedIn && (
      <Button onClick={() => handleLogout()} style={{ position: 'fixed', top: '10px', right: '10px' }}>
        Logout
      </Button>
    )}
    <Row className="py-auto m-0 d-flex justify-content-center align-items-center min-vh-100">
      <Col lg={5} md={8} sm={10} xs={12} className="d-flex justify-content-center">
        <Card className="px-xl-80 px-40 my-40 border-0">
          {!$loader.value?.isLoading ? (
            <Image src={loginLogo} alt="Two People Reading" className="mx-auto my-24" hidden={$onboarding.value.step === 'complete'} />
          ) : (
            <ComponentLoader className="my-24" />
          )}
          {children}
          <Container className="text-center mb-24" hidden={$onboarding.value.step === 'complete'}>
            By continuing you agree to our{' '}
            <a
              href="https://accru.co/terms-conditions/"
              target="_blank"
              rel="terms noreferrer"
            >
              terms
            </a>,{' '}
            <a
              href="https://accru.co/security/"
              target="_blank"
              rel="terms noreferrer"
            >
              cookie
            </a> and{' '}
            <a
              href="https://accru.co/privacy/"
              target="_blank"
              rel="terms noreferrer"
            >
              privacy policy
            </a>
            {window.location.pathname === '/onboarding' && (
              <div>
                <div
                  onClick={() => handleLogout()}
                  role="button"
                  tabIndex={0}
                  className="cursor-pointer text-primary mt-16"
                >
                  <u>Back to login</u>
                </div>
              </div>
            )}
          </Container>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AuthLayout;
