import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loadable from 'components/global/Loadable';
import { Button, Form, InputGroup } from 'react-bootstrap';

export const PasswordInput = ({ signal, name, visabilityName, onEnter }) => {
  if (!signal || !name || !visabilityName) {
    return new Error(`Universal Input has missing required variable ${name})`);
  }
  return (
    <InputGroup className="password-form-control">
      <Loadable signal={signal} animation="glow">
        <Form.Control
          type={!signal.value[visabilityName] ? 'password' : 'text'}
          value={signal?.value[name]}
          placeholder="************"
          className="border-0"
          name="password"
          onChange={(e) => signal.update({ [name]: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && typeof onEnter === 'function') {
              e.preventDefault();
              onEnter();
            }
          }}
        />
      </Loadable>
      <InputGroup.Text className="password-input-eye p-0">
        <Button
          role="button"
          tabIndex="0"
          onClick={() => signal.update({ [visabilityName]: !signal.value[visabilityName] })}
          variant="password-eye"
          className="text-primary px-0 w-100 rounded-0 border-dark"
        >
          <FontAwesomeIcon
            className="p-0 d-flex justify-content-center align-content-center"
            icon={signal.value[visabilityName] ? faEye : faEyeSlash}
            width="20px"
          />
        </Button>
      </InputGroup.Text>
    </InputGroup>
  );
};
export default PasswordInput;
