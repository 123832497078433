import { accruClient, apiFormDataFetch } from 'api';
import { validateEmail, validateLength } from 'utils/validateInput';
import { $authToken } from 'signals/Authentication.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { validateState, validateZip } from 'utils/validation';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';
import $user from 'signals/User.signals';
import { fetchAndSetUserData } from 'components/views/Auth/_shared/Auth.helpers';
import { COUNTRY_ISO_3 } from 'accru-client';
import { $settingsOrganization, $settingsOrganizationUI, $settingsOrganizationCollaboratorForm } from './SettingsOrganization.signals';

const formatTitle = (str) => {
  const parts = str.split(/(?=[A-Z0-9])/);
  const formattedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
  const formattedString = formattedParts.join(' ');
  return formattedString;
};

export const handleBrowse = async () => {
  const fileInput = document.getElementById('file-input');
  fileInput.click();
};

export const handleVerifyContactFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    $settingsOrganization.update({
      contactVerificationCode: oldFormData,
    });
  }
};

export const cancelOrganizationContactUpdate = () => {
  const { currentOrganization } = $user.value;
  if (currentOrganization) {
    $settingsOrganization.update({
      email: currentOrganization.data.email,
      phoneNumber: currentOrganization.data.phone_number,
      contactVerificationCode: $settingsOrganization.initialValue.contactVerificationCode,
    });
    $settingsOrganizationUI.update({
      contactPage: 'contact',
    });
  }
};

export const fetchOrganizationData = async (organizationId) => {
  try {
    $settingsOrganization.loadingStart();
    await fetchAndSetUserData();
    const response = await accruClient.organizations.getOne({
      organizationId,
    });

    if (response) {
      const hasSubmitted = () => {
        if (
          response?.business_name ||
          response?.business_address_line_1 ||
          response?.business_address_line_2 ||
          response?.business_address_city ||
          response?.business_address_state ||
          response.business_tax_code_type ||
          response.business_tax_code
        ) {
          return true;
        }
        return false;
      };

      $settingsOrganization.update({
        companyLogo: response.logo_picture_file?.public_url || null,
        companyName: response.name || '',
        companyAddressLine1: response.address_line_1 || '',
        companyAddressLine2: response.address_line_2 || '',
        companyAddressCity: response.address_city || '',
        companyAddressState: response.address_state || '',
        companyAddressZipCode: response.address_zip_code || '',
        phoneNumber: response.phone_number || '',
        verifiedPhoneNumber: response.phone_number || '',
        email: response.email || '',
        verifiedEmail: response.email || '',
        primaryContact: response.primary_contact_name || '',
        timeZone: response.timezone || '',
        hasSubmitted: hasSubmitted(),
      });
    }
    console.log($settingsOrganization.value, response);
    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const updateOrganizationEmailStart = async () => {
  try {
    $settingsOrganization.loadingStart();
    const {
      email,
    } = $settingsOrganization.value;
    validateEmail(email);
    const response = await accruClient.organizations.updateEmailStart({
      organizationId: $user.value.currentOrganization?.id,
      data: { email },
    });
    if (response) {
      handleNotification(`Verification code has been sent to ${email}`, { variant: 'success' });
      $settingsOrganizationUI.update({
        ...$settingsOrganizationUI.value,
        contactPage: 'validation',
        verificationType: 'email',
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};
export const updateOrganizationEmailFinish = async () => {
  try {
    $settingsOrganization.loadingStart();
    const { contactVerificationCode, email } = $settingsOrganization.value;
    const response = await accruClient.organizations.updateEmailFinish({
      organizationId: $user.value.currentOrganization?.id,
      data: {
        email,
        verification_code: contactVerificationCode.join(''),
      },
    });
    if (response) {
      $settingsOrganization.reset();
      handleNotification('The organization email address was updated!', { variant: 'success' });
      $settingsOrganization.update({
        currentTab: 'contact',
      });
      $settingsOrganizationUI.update({
        ...$settingsOrganizationUI.value,
        contactPage: 'contact',
        verificationType: null,
      });
    }
    await fetchOrganizationData($user.value.currentOrganization.id);

    return response;
  } catch (error) {
    handleNotification(error);
    $settingsOrganization.update({
      contactVerificationCode: $settingsOrganization.initialValue.contactVerificationCode,
    });
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const updateOrganizationPhone = async () => {
  try {
    $settingsOrganization.loadingStart();
    const {
      phoneNumber,
    } = $settingsOrganization.value;
    const formattedPhone = formatPhoneNumForApi(phoneNumber);

    await accruClient.organizations.update({
      organizationId: $user.value.currentOrganization.id,
      data: {
        phone_number: formattedPhone,
      },
    });

    await fetchOrganizationData($user.value.currentOrganization.id);

    handleNotification('The organization phone number was updated!', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const uploadCompanyLogo = async (event, orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

    const file = event.target.files[0];
    if (!file) {
      $settingsOrganization.update({
        companyLogo: null,
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size too large. Maximum size allowed is 10 MB.');
    }

    if (!file.type.startsWith('image/')) {
      throw new Error('Only image files are allowed.');
    }

    if (file) {
      const formData = new FormData();
      formData.append('picture', file);
      const res = await apiFormDataFetch({
        path: `/organization/${orgId}/profile-picture`,
        method: 'POST',
        formData,
        token: $authToken.value,
      });

      if (res.message) {
        throw new Error(res.message);
      }

      handleNotification('Your Company Logo has been Updated!', { variant: 'success' });
      if (res.logo_picture_file.public_url) {
        $settingsOrganization.update({
          companyLogo: res.logo_picture_file.public_url,
        });
      }
    } else {
      $settingsOrganization.update({
        companyLogo: null,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const deleteCompanyLogo = async (orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const response = await accruClient.organizations.deleteOrganizationLogo({
      organizationId: orgId,
    });
    if (response) {
      handleNotification('Your Company Logo has been deleted!', { variant: 'success' });
      $settingsOrganization.update({
        companyLogo: null,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const updateOrganizationData = async (orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const {
      companyName,
      companyAddressLine1,
      companyAddressLine2,
      companyAddressCity,
      companyAddressState,
      companyAddressZipCode,
      phoneNumber,
      primaryContact,
      timeZone,
    } = $settingsOrganization.value;

    const data = {
      phone_number: formatPhoneNumForApi(phoneNumber),
      primary_contact_name: primaryContact,
      timezone: timeZone,
      name: companyName,
    };

    const companyAddressValidationObj = {
      companyAddressLine1,
      companyAddressCity,
      companyAddressState,
      companyAddressZipCode,
    };

    Object.keys(data).forEach(key => {
      const title = formatTitle(key);
      validateLength(data[key], title, 1, 100);
    });

    if (Object.values(companyAddressValidationObj).some(e => e.length)) {
      validateState(companyAddressState);
      validateZip(companyAddressZipCode);
      Object.keys(companyAddressValidationObj).forEach(key => {
        const title = formatTitle(key);
        validateLength(companyAddressValidationObj[key], title, 1, 100);
      });
      Object.assign(data, {
        address_city: companyAddressCity,
        address_line_1: companyAddressLine1,
        address_state: companyAddressState,
        address_zip_code: companyAddressZipCode,
        address_country_code_iso_3: COUNTRY_ISO_3.USA,
      });
      if (companyAddressLine2?.length) {
        data.address_line_2 = companyAddressLine2;
      }
    }

    const response = await accruClient.organizations.update({
      organizationId: orgId,
      data,
    });
    await fetchAndSetUserData();
    handleNotification('Your Organization has been updated!', { variant: 'success' });
    return response;
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const fetchOrganizationCollaborators = async (organizationId) => {
  try {
    $settingsOrganization.loadingStart();

    const [collaborators, invites] = await Promise.all([
      accruClient.organizations.getCollaborators({ organizationId }),
      accruClient.organizations.getCollaboratorInvites({
        organizationId,
        accepted: false,
        canceled: false,
        expired: false,
        rejected: false,
      }),
    ]);

    $settingsOrganization.update({
      users: collaborators.map((obj) => ({
        userId: obj.id,
        orgId: obj.organization_id,
        role: obj.role,
        sendInvoiceReminders: obj.send_invoice_reminders,
        email: obj.user.email,
        firstName: obj.user.first_name,
        lastName: obj.user.last_name,
        profilePicture: obj?.user?.profile_picture_file?.public_url || null,
      })),
      invites: invites.items.map(obj => ({
        email: obj.email,
        role: obj.role,
        createdAt: obj.created_at,
        invitedBy: obj.created_by_user?.email,
        id: obj.id,
      })),
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const updateCollaboratorRole = async (userId, orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const {
      newRole,
      newSendInvoiceReminders,
    } = $settingsOrganizationCollaboratorForm.value;

    const response = await accruClient.organizations.updateCollaborator({
      organizationUserId: userId,
      organizationId: orgId,
      data: {
        role: newRole,
        send_invoice_reminders: Boolean(newSendInvoiceReminders),
      },
    });
    if (response) {
      const tempUsers = $settingsOrganization.value.users;
      const index = $settingsOrganization.value.users.findIndex(user => user.userId === userId);
      tempUsers[index] = {
        ...$settingsOrganization.value.users[index],
        role: newRole,
      };
      if (tempUsers) {
        $settingsOrganization.update({
          users: tempUsers,
        });
        $settingsOrganizationUI.update({
          usersPage: 'users',
        });
      }
      handleNotification('User has been updated!', { variant: 'success' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const deleteCollaborator = async (userId, orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const response = await accruClient.organizations.deleteCollaborator({
      organizationUserId: userId,
      organizationId: orgId,
    });
    if (response) {
      const tempUsers = $settingsOrganization.value.users;
      const index = $settingsOrganization.value.users.findIndex(user => user.userId === userId);
      tempUsers.splice(index, 1);
      if (tempUsers) {
        $settingsOrganization.update({
          users: tempUsers,
        });
      }
      handleNotification('User has been deleted!', { variant: 'success' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const deleteCollaboratorInvite = async (inviteId) => {
  try {
    $settingsOrganization.loadingStart();
    const response = await accruClient.organizations.deleteCollaboratorInvite({
      organizationInviteId: inviteId,
      organizationId: $user.value.currentOrganization.id,
    });
    if (response) {
      const tempInvites = $settingsOrganization.value.invites;
      const index = $settingsOrganization.value.invites.findIndex(invite => invite.id === inviteId);
      tempInvites.splice(index, 1);
      if (tempInvites) {
        $settingsOrganization.update({
          invites: tempInvites,
        });
      }
      handleNotification('Invite has been deleted!', { variant: 'success' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const inviteUserToOrganization = async (orgId) => {
  try {
    $settingsOrganization.loadingStart();
    const {
      newUserEmail,
      newUserRole,
    } = $settingsOrganizationCollaboratorForm.value;

    validateEmail(newUserEmail);

    const response = await accruClient.organizations.inviteCollaborator({
      organizationId: orgId,
      data: {
        email: newUserEmail,
        role: newUserRole,
      },
    });
    if (response) {
      handleNotification('User has been invited!', { variant: 'success' });
      $settingsOrganizationCollaboratorForm.update({
        newUserEmail: '',
        newUserRole: 'Select',
      });
      $settingsOrganizationUI.update({
        usersPage: 'users',
      });
    }
    await fetchOrganizationCollaborators(orgId);
    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};

export const resendInvite = async (organizationInviteId) => {
  try {
    $settingsOrganization.loadingStart();

    await accruClient.organizations.resendCollaboratorInvite({
      organizationId: $user.value.currentOrganization.id,
      organizationInviteId,
    });

    handleNotification('Invite has been re-sent!', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsOrganization.loadingEnd();
  }
};
