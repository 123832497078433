import { Button, Col, Dropdown, Image, Row } from 'react-bootstrap';
import sortAlt from 'images/sort-alt.svg';
import filterAlt from 'images/filter-alt.svg';
import successCircle from 'images/successCircle.svg';
import alertCircle from 'images/alertCircle.svg';
import failedCircle from 'images/failedCircle.svg';
import useEffectAsync from 'utils/useEffectAsync';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isOddOrEven from 'utils/oddOrEven';
import { filterSync, sortFlip, fetchAndSetLogData } from 'components/views/SettingsLogs/_helpers/SettingsLogs.helpers';
import moment from 'moment';
import Pagination from 'components/global/Pagination';
import Loadable from 'components/global/Loadable';
import useCleanup from 'hooks/useCleanup';
import { $settingsLogs, $settingsLogsUI, settingsLogsSignals } from './_helpers/SettingsLogs.signals';
import SettingsLogsDetailModal from './_components/SettingsLogsDetailModal';

const SettingsLogs = () => {
  const { logs, totalCount } = $settingsLogs.value;
  const { page, perPage } = $settingsLogsUI.value;

  useCleanup(settingsLogsSignals);

  useEffectAsync(
    [fetchAndSetLogData],
    [page, perPage],
  );

  return (
    <div>
      <SettingsLogsDetailModal />
      <h1 className="d-none d-lg-block d-xl-block">Logs</h1>
      <Row className="mx-0 bg-gray-50 rounded mt-24 pb-16">
        <Row className="mx-8 mb-24 mt-8">
          <Col sm={10} className="p-0">
            <h2 className="mb-0 fw-700 fw-bold">Sync History</h2>
          </Col>
          <Col sm={1} className="p-0 my-auto">
            <Button type="button" variant="eye" className="p-0 bg-white text-primary" onClick={() => sortFlip()}>
              <Image src={sortAlt} alt="sort-alt" />
            </Button>
          </Col>
          <Col sm={1} className="p-0 my-auto">
            <Dropdown>
              <Button
                variant="eye"
                className="p-0 bg-white text-primary my-auto"
              >
                <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle">
                  <Image src={filterAlt} alt="filter-alt" />
                </Dropdown.Toggle>
              </Button>
              <Dropdown.Menu className="custom-nav-dropdown p-0">
                <Dropdown.Item className="rounded" onClick={() => filterSync('PASS')}>Passed</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('ERROR')}>Errors</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('FAIL')}>Failed</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('NONE')}>Reset</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Loadable signal={$settingsLogs} template="component">
          {logs && logs.map((log, idx) => (
            <Row
              key={idx}
              className={`${isOddOrEven(idx) === 'Odd' ? 'bg-gray-200' : 'bg-none'} py-16 px-24 mx-0 cursor-pointer`}
              onClick={() => $settingsLogsUI.update({
                isDetailModalOpen: true,
                selectedLog: log,
              })}
            >
              <Col sm={3} className="px-0 my-auto">
                {moment(log.created_at).format('l')}
              </Col>
              <Col sm={5} className="px-0 my-auto">
                {log.status_description === 'PASS' && (
                  <div className="d-flex align-items-center">
                    <Image src={successCircle} alt="successCircle" />
                    <div className="ms-8">Successful</div>
                  </div>
                )}
                {log.status_description === 'ERROR' && (
                  <div className="d-flex align-items-center">
                    <Image src={alertCircle} alt="alertCircle" />
                    <div className="ms-8">{log.errors.length} issue{log.errors.length !== 1 && 's'} found</div>
                  </div>
                )}
                {log.status_description === 'FAIL' && (
                  <div className="d-flex align-items-center">
                    <Image src={failedCircle} alt="failedCircle" />
                    <div className="ms-8">Failed</div>
                  </div>
                )}
              </Col>
              <Col sm={3} className="px-0 d-flex justify-content-end">
                <div className="text-dark">{log.trigger_code && `${log.trigger_code.charAt(0).toUpperCase() + log.trigger_code.slice(1).toLowerCase()}`}</div>
              </Col>
              <Col sm={1} className="px-0 d-flex justify-content-end">
                <FontAwesomeIcon className="text-dark lead" icon={faCircleArrowRight} />
              </Col>
            </Row>
          ))}
          <Pagination itemsPerPageAmount={perPage} paginationMaxButtonAmount={5} totalItemsCount={totalCount} currentPageItemsCount={logs.length} currentPage={page} setCurrentPage={(value) => $settingsLogsUI.update({ page: value })} />
        </Loadable>
      </Row>
    </div>
  );
};

export default SettingsLogs;
