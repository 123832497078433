import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import logEvent from 'utils/logEvent';
import { useEffectAsync } from 'utils/useEffectAsync';
import updateQueryParams, { updateSignalWithQueryParams } from 'utils/updateQueryParams';
import ContentWrapper from 'components/global/ContentWrapper';
import BillsFilters from 'components/views/Bills/_components/BillsFilters';
import Totals from 'components/global/Totals';
import BillsBulkActions from 'components/views/Bills/_components/BillsBulkActions';
import SignalTable from 'components/global/SignalTable';
import Pagination from 'components/global/Pagination';
import {
  fetchAndSetBills,
  fetchAndSetVendorOptions,
  handleBillsRowClick,
} from 'components/views/Bills/Bills.helpers';
import {
  $bills,
  $billsFilter,
  $billsUI,
  billsSignals,
} from 'components/views/Bills/Bills.signals';
import { setBillsTableHeaders, getBillsTableRows } from 'components/views/Bills/billTableFormat.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useCleanup from 'hooks/useCleanup';

const Bills = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const organizationVendorId = urlParams.get('organizationVendorId');

  useCleanup(billsSignals);

  useEffect(() => {
    logEvent({ name: 'view_all_bills' });
    $billsUI.reset();
    updateSignalWithQueryParams($billsFilter);
    setBillsTableHeaders();
  }, [location]);

  useEffect(() => {
    updateQueryParams($billsFilter.value);
  }, [$billsFilter.value]);

  useEffectAsync(fetchAndSetVendorOptions, []);

  useEffectAsync(async () => {
    await fetchAndSetBills();
  }, [$billsFilter.value], 400);

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0">
      <Container className="p-0 pt-40 pt-lg-80">
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center text-center">
              <div className="me-2 pe-2">
                <h1 className="text-center me-2">Bills</h1>
              </div>
              <Button
                variant="info"
                className="mb-8 circle-30 d-flex align-items-center justify-content-center"
                onClick={() => history.push('/bills/add')}
              >
                <FontAwesomeIcon icon={faPlus} width={12} />
              </Button>
            </div>
          </Col>
        </Row>
        <Totals overdue={$bills.value.overdue_amount} open={$bills.value.balance} $signal={$bills} />
        <div className={$billsUI.value.selectedItems?.length > 0 ? 'd-block' : 'd-none'}>
          <BillsBulkActions />
        </div>
        <div className={$billsUI.value.selectedItems?.length > 0 ? 'd-none' : 'd-block'}>
          <BillsFilters organizationVendorId={organizationVendorId} />
        </div>
        <Row className="mt-32">
          <Col>
            <SignalTable
              $ui={$billsUI}
              $filter={$billsFilter}
              rows={getBillsTableRows($bills.value.items)}
              headers={$billsUI.value.tableHeaders}
              isSelectable
              onRowClick={(row) => handleBillsRowClick({ bill: row.data })}
              canFilterColumns
            />
            <Pagination
              itemsPerPageAmount={20}
              paginationMaxButtonAmount={5}
              totalItemsCount={$bills.value.totalCount}
              currentPageItemsCount={$bills.value.items?.length || 0}
              currentPage={$billsFilter.value.page}
              setCurrentPage={(value) => $billsFilter.update({ page: value })}
            />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default Bills;
