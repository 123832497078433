import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import Signal from 'signals/Signal';
import { acceptConnection, inviteCustomer, inviteVendor, rejectConnection } from 'components/helpers/Connection.helpers';
import { updateSingleSignalCustomer } from 'components/views/Customers/_helpers/Customers.helpers';
import { $customerDetail } from 'components/views/Customers/_helpers/Customers.signals';
import {
  getVendor,
  updateSingleSignalVendor,
} from 'components/views/Vendors/_helpers/Vendors.helpers';
import { $vendorDetail } from 'components/views/Vendors/_helpers/Vendors.signals';

import $user from 'signals/User.signals';

export const $sidePanelSettingsConnections = Signal({});

export const updateEntitySignal = async ({ type, entity, data }) => {
  if (type === 'customer') {
    const updatedCustomer = data || await accruClient.customers.getOne({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId: entity.id,
    });
    updateSingleSignalCustomer({ customer: updatedCustomer });
  } else if (type === 'vendor') {
    const updatedVendor = data || await getVendor({ organizationVendorId: entity.id });
    updateSingleSignalVendor({ vendor: updatedVendor });
  } else {
    throw new Error('Invalid type.');
  }
};

export const updateConnLock = async ({ type, selectedId, action }) => {
  try {
    if (type === 'customer') {
      if (action === 'lock') {
        const data = accruClient.connections.customerLock({
          organizationId: $user.value.currentOrganization.id,
          organizationCustomerId: selectedId,
        });

        return data;
      }

      const data = accruClient.connections.customerUnlock({
        organizationId: $user.value.currentOrganization.id,
        organizationCustomerId: selectedId,
      });

      return data;
    } if (type === 'vendor') {
      if (action === 'lock') {
        const data = accruClient.connections.vendorLock({
          organizationId: $user.value.currentOrganization.id,
          organizationVendorId: selectedId,
        });

        return data;
      }

      const data = accruClient.connections.vendorUnlock({
        organizationId: $user.value.currentOrganization.id,
        organizationVendorId: selectedId,
      });

      return data;
    }
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const handleUpdateConnLock = async ({ type, action }) => {
  try {
    $sidePanelSettingsConnections.loadingStart();

    const entity = type === 'customer'
      ? $customerDetail.value
      : $vendorDetail.value;

    if (!entity) throw new Error('No entity selected.');

    const data = await updateConnLock({ type, selectedId: entity.id, action });

    await updateEntitySignal({ type, entity, data });
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsConnections.loadingEnd();
  }
};

export const handleConnect = async ({ type }) => {
  try {
    $sidePanelSettingsConnections.loadingStart();

    const entity = type === 'customer'
      ? $customerDetail.value
      : $vendorDetail.value;

    if (!entity) throw new Error('No entity selected.');

    if (entity.connection?.id) {
      await acceptConnection({ organizationConnectionId: entity.connection.id });
      await updateEntitySignal({ type, entity });
    } else {
      if (type === 'customer') {
        await inviteCustomer({ organizationCustomerId: entity.id });
      } else {
        await inviteVendor({ organizationVendorId: entity.id });
      }
      await updateEntitySignal({ type, entity });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsConnections.loadingEnd();
  }
};

export const handleDisconnect = async ({ type }) => {
  try {
    $sidePanelSettingsConnections.loadingStart();

    const entity = type === 'customer'
      ? $customerDetail.value
      : $vendorDetail.value;

    if (!entity) throw new Error('No entity selected.');
    if (!entity.connection?.id) throw new Error('No connection found.');

    await rejectConnection({
      organizationConnectionId: entity.connection.id,
    });

    await updateEntitySignal({ type, entity });
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsConnections.loadingEnd();
  }
};
