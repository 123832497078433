import Signal from 'signals/Signal';

export const $settingsOrganization = Signal({
  companyLogo: null,
  companyName: '',
  companyAddressLine1: '',
  companyAddressLine2: '',
  companyAddressCity: '',
  companyAddressState: '',
  companyAddressZipCode: '',
  contactVerificationCode: ['', '', '', '', '', ''],
  phoneNumber: '',
  email: '',
  primaryContact: '',
  timeZone: '',
  users: [],
  invites: [],
});

export const $settingsOrganizationUI = Signal({
  currentTab: 'company-info',
  contactPage: 'contact',
  verificationType: null,
  usersPage: 'users',
});

export const $settingsOrganizationCollaboratorForm = Signal({
  newRole: 'Select',
  newSendInvoiceReminders: false,
  newUserEmail: '',
  newUserRole: 'Select',
  selectedUser: null,
});

export const settingsOrganizationSignals = [
  $settingsOrganization,
  $settingsOrganizationUI,
  $settingsOrganizationCollaboratorForm,
];
