/* eslint-disable no-param-reassign */
import { signal } from '@preact/signals-react';

/**
 * @template T
 * @typedef {Object} DefaultSignalData
 * @property {boolean} isLoading Whether the signal is currently loading.
 */

/**
 * @template T
 * @typedef {Object} SignalOutput
 * @property {DefaultSignalData<T> & T} value The value returned by the Signal function.
 * @property {() => void} loadingStart Sets the isLoading Property to true
 * @property {() => void} loadingEnd Sets the isLoading Property to false
 * @property {() => void} reset Resets the value of the signal to the original value established at creation
 * @property {() => DefaultSignalData<T> & T} peek Get the value without subscribing to the signal
 * @property {DefaultSignalData<T> & T} previousValue The value of the signal before the last update
 * @property {DefaultSignalData<T> & T} initialValue The initial value of the signal
 * @property {(payload: T) => void} update Updates the value of the signal without needing to spread props
 */

/**
 * @template T
 * @typedef {SignalOutput<T> & import('@preact/signals-react').Signal<T>} Signal
 */

const updateSignal = (signalToUpdate, newVal) => {
  signalToUpdate.previousValue = signalToUpdate.value;
  if (!signalToUpdate) {
    throw new Error('Signal is not defined');
  }

  if (Array.isArray(signalToUpdate.value)) {
    signalToUpdate.value = [...newVal];
  } else if (typeof signalToUpdate.value === 'object') {
    signalToUpdate.value = {
      ...signalToUpdate.value,
      ...newVal,
    };
  } else {
    signalToUpdate.value = newVal;
  }

  return signal;
};

class SignalManager {
  constructor() {
    if (!SignalManager.instance) {
      this.signals = [];
      SignalManager.instance = this;
    }
    return SignalManager.instance;
  }

  /**
   * Creates a signal with the initial state.
   *
   * @template T The type of the initial state.
   * @param {T} initState The initial state of the signal.
   * @param {boolean} shouldPersist Whether the signal should persist its state after a reset.
   * @returns {Signal<T>} The created signal object.
   */
  createSignal(initState, shouldPersist = false) {
    const rawSignal = signal(initState);
    rawSignal.previousState = null;
    if (shouldPersist) {
      rawSignal.value.shouldPersist = true;
    }

    rawSignal.update = (payload) => updateSignal(rawSignal, payload);

    rawSignal.reset = () => {
      rawSignal.value = initState;
      if (shouldPersist) {
        rawSignal.value.shouldPersist = true;
      }
    };

    rawSignal.initialValue = initState;

    rawSignal.loadingStart = () => updateSignal(rawSignal, { isLoading: true });
    rawSignal.loadingEnd = () => updateSignal(rawSignal, { isLoading: false });

    this.signals.push(rawSignal);
    return rawSignal;
  }

  resetAllSignals() {
    this.signals.forEach((s) => {
      if (!s.value.shouldPersist) {
        s.reset();
      }
    });
  }
}

export const signalManager = new SignalManager();

const createSignal = /** @type {SignalManager['createSignal']} */ (signalManager.createSignal.bind(signalManager));
export default createSignal;
