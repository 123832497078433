const { getConfig } = require('config/config');

const isBackendConnectionUp = async () => {
  const backendUri = getConfig('BACKEND_GRAPHQL_ENDPOINT').replace('/graphql', '');

  try {
    const response = await fetch(`${backendUri}/health/liveness`);
    if (response.status === 200) { return true; }
    return false;
  } catch {
    return false;
  }
};

export default isBackendConnectionUp;
