/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import editWithBlueTriangle from 'images/editWithBlueTriangle.svg';
import closeButton from 'images/closeButton.svg';
import { $customerDetail, $customersUi } from 'components/views/Customers/_helpers/Customers.signals';
import { $vendorDetail, $vendorsUI } from 'components/views/Vendors/_helpers/Vendors.signals';
import Loadable from 'components/global/Loadable';
import { isProdVersion } from 'components/views/MVP/MVP.helpers';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import { $sidePanelSettingsDetails, $sidePanelFormDataDetails, handleSubmitDetails, handleEditEntityDetailsClick } from './SidePanel.helpers';

const SidePanelDetails = ({ type, className }) => {
  const entity = type === 'customer'
    ? $customerDetail.value
    : $vendorDetail.value;

  return (
    <Card className={`bg-gray-50 px-16 px-md-56 py-32 ${className || ''}`}>
      {isProdVersion(2) && (
        <Row className="mx-0">
          <Col className="d-flex justify-content-end">
            <div
              className="p-8 cursor-pointer"
              onClick={() => handleEditEntityDetailsClick({ type })}
            >
              <Image src={$sidePanelSettingsDetails.value.isEditing ? closeButton : editWithBlueTriangle} height={$sidePanelSettingsDetails.value.isEditing ? 16 : 28} />
            </div>
          </Col>
        </Row>
      )}
      {$sidePanelSettingsDetails.value.isEditing ? (
        <>
          <Row>
            <Col>
              <div className="base fw-800">
                {type === 'customer' ? 'Client/Company name' : 'Vendor/Company name'}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <UniversalInput
                placeholder="Name"
                name="name"
                signal={$sidePanelFormDataDetails}
              />
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <div className="base fw-800">
                Email address
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <UniversalInput
                placeholder="Email"
                name="email"
                signal={$sidePanelFormDataDetails}
              />
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <div className="base fw-800">
                Phone number
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <PhoneNumberInput
                name="phoneNumber"
                placeholder="Phone number (optional)"
                value={$sidePanelFormDataDetails?.value.phone_number || ''}
                customOnChange={(e) => $sidePanelFormDataDetails.update({ phone_number: e.target.value })}
                signal={$sidePanelFormDataDetails}
                className="mb-16"
              />
            </Col>
          </Row>
          <Row className="mt-32">
            <Col className="col-4 offset-4">
              <Button className="w-100 py-8" onClick={() => handleSubmitDetails({ type })}>SAVE</Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <div className="base fw-800">
                Client/Company name
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <Loadable template="text" signal={entity}>
                <h3>{entity?.name}</h3>
              </Loadable>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <div className="base fw-800">
                Email address
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <Loadable template="text" signal={entity}>
                <h3>{entity?.email}</h3>
              </Loadable>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <div className="base fw-800">
                Phone number
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <Loadable template="text" signal={entity}>
                <h3>{entity?.phone_number || 'N/A'}</h3>
              </Loadable>
            </Col>
          </Row>
        </>
      )}
      <SimpleModal
        isShow={$sidePanelSettingsDetails.value.dataLockedModalOpen}
        title="The data is locked to sync contact info"
        onClose={() => $sidePanelSettingsDetails.update({ dataLockedModalOpen: false })}
        onCancel={() => {
          $sidePanelSettingsDetails.update({ dataLockedModalOpen: false });
        }}
        onSave={() => {
          if (type === 'customer') $customersUi.update({ openTab: 'connections' });
          else $vendorsUI.update({ openTab: 'connections' });
          $sidePanelSettingsDetails.update({ dataLockedModalOpen: false });
        }}
        saveText="Go to Connection"
        cancelText="Understood"
      >
        This data is locked because the &quot;Sync Contact Info&quot; option is enabled in the settings.
      </SimpleModal>
    </Card>
  );
};

export default SidePanelDetails;
