import { accruClient, apiFormDataFetch } from 'api';
import { validateEmail } from 'utils/validateInput';
import { auth } from 'utils/firebase';
import { $settingsProfile, $settingsProfileUI } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';
import $user from 'signals/User.signals';
import { $authToken } from 'signals/Authentication.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';

// - basic info tab - //

export const updateProfileData = async () => {
  try {
    $settingsProfile.loadingStart();
    $user.loadingStart();
    const {
      firstName,
      lastName,
    } = $settingsProfile.value;

    const { timezone, language } = $user.value.user;
    const response = await accruClient.users.update({
      data: {
        first_name: firstName,
        last_name: lastName,
        language,
        timezone: timezone || 'America/Los_Angeles',
      },
    });

    if (response) {
      handleNotification('Your Profile Info has been updated!', { variant: 'success' });
      $user.update({
        user: {
          ...$user.value.user,
          first_name: firstName,
          last_name: lastName,
        },
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
    $user.loadingEnd();
  }
};

export const cancelProfileUpdate = async () => {
  try {
    $settingsProfile.loadingStart();
    const { user } = $user.value;
    if (user) {
      $settingsProfile.update({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const uploadProfilePicture = async (event) => {
  try {
    $settingsProfile.loadingStart();
    $user.loadingStart();
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

    const file = event.target.files[0];
    if (!file) {
      $settingsProfile.update({
        profilePhoto: null,
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size too large. Maximum size allowed is 10 MB.');
    }

    if (!file.type.startsWith('image/')) {
      throw new Error('Only image files are allowed.');
    }

    if (file) {
      const formData = new FormData();
      formData.append('picture', file);
      const res = await apiFormDataFetch({
        path: '/user/profile-picture',
        method: 'POST',
        formData,
        token: $authToken.value,
      });
      handleNotification('Your Profile Photo has been updated!', { variant: 'success' });
      if (res.profile_picture_file.public_url) {
        $settingsProfile.update({
          profilePhoto: res.profile_picture_file.public_url,
        });
        $user.update({
          user: {
            ...$user.value.user,
            profile_picture_file: {
              public_url: res.profile_picture_file.public_url,
            },
          },
        });
      }
    } else {
      $settingsProfile.update({
        profilePhoto: null,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
    $user.loadingEnd();
  }
};

export const deleteProfilePicture = async () => {
  try {
    $settingsProfile.loadingStart();
    const removeProfilePicture = await accruClient.users.removeProfilePicture();
    if (removeProfilePicture) {
      handleNotification('Your Profile Photo has been deleted', { variant: 'success' });
      $user.update({
        ...$user.value,
        user: {
          ...$user.value.user,
          profile_picture_file: null,
        },
      });
      $settingsProfile.update({ profilePhoto: null });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const handleBrowse = async () => {
  const fileInput = document.getElementById('file-input');
  fileInput.click();
};

// - contact info tab - //

export const updateEmailStart = async () => {
  try {
    $settingsProfile.loadingStart();
    const {
      email,
    } = $settingsProfile.value;
    validateEmail(email);
    const response = await accruClient.users.updateEmail({
      data: { email },
    });
    if (response) {
      handleNotification(`Verification code has been sent to ${email}`, { variant: 'success' });
      $settingsProfileUI.update({
        ...$settingsProfileUI.value,
        contactPage: 'validation',
        verificationType: 'email',
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const updateEmailFinish = async () => {
  try {
    $settingsProfile.loadingStart();
    const { contactVerificationCode, email } = $settingsProfile.value;
    const result = await accruClient.users.verifyUpdatedEmail({
      data: {
        email,
        verification_code: contactVerificationCode.join(''),
      },
    });
    if (result) {
      $user.update({
        ...$user.value,
        user: {
          ...$user.value.user,
          email,
        },
      });
      $settingsProfile.reset();
      $settingsProfile.update({
        currentTab: 'contact',
      });
      $settingsProfileUI.update({
        ...$settingsProfileUI.value,
        contactPage: 'contact',
        verificationType: null,
      });
      handleNotification('Your email has been updated!', { variant: 'success' });
    }

    try {
      await auth.currentUser.reload();
    } catch (error) {
      handleNotification('Please sign in again to see the changes');
    }
  } catch (error) {
    handleNotification(error);
    $settingsProfile.update({
      contactVerificationCode: $settingsProfile.initialValue.contactVerificationCode,
    });
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const updatePhoneStart = async () => {
  try {
    $settingsProfile.loadingStart();
    const {
      phoneNumber,
    } = $settingsProfile.value;
    const formattedPhone = formatPhoneNumForApi(phoneNumber);

    const result = await accruClient.users.updatePhoneNumber({
      data: {
        phone_number: formattedPhone,
      },
    });

    if (result) {
      $settingsProfileUI.update({
        ...$settingsProfileUI.value,
        contactPage: 'validation',
        verificationType: 'phone',
      });
      handleNotification(`Verification code has been sent to ${formattedPhone}`, { variant: 'success' });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const updatePhoneFinish = async () => {
  try {
    $settingsProfile.loadingStart();
    const { contactVerificationCode, phoneNumber } = $settingsProfile.value;
    const formattedPhone = formatPhoneNumForApi(phoneNumber);
    const response = await accruClient.users.verifyUpdatedPhoneNumber({
      data: {
        phone_number: formattedPhone,
        verification_code: contactVerificationCode.join(''),
      },
    });
    if (response) {
      $user.update({
        ...$user.value,
        user: {
          ...$user.value.user,
          phone_number: phoneNumber,
        },
      });
      $settingsProfile.reset();
      handleNotification('Your Phone Number has been updated!', { variant: 'success' });
      $settingsProfile.update({
        currentTab: 'contact',
      });
      $settingsProfileUI.update({
        ...$settingsProfileUI.value,
        contactPage: 'contact',
        verificationType: null,
      });
    }
    return response;
  } catch (error) {
    handleNotification(error);
    $settingsProfile.update({
      contactVerificationCode: $settingsProfile.initialValue.contactVerificationCode,
    });
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const handleVerifyContactFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    $settingsProfile.update({
      contactVerificationCode: oldFormData,
    });
  }
};

export const cancelContactUpdate = () => {
  const { user } = $user.value;
  if (user) {
    $settingsProfile.update({
      email: user.email,
      phoneNumber: user.phone_number,
      contactVerificationCode: $settingsProfile.initialValue.contactVerificationCode,
    });
    $settingsProfileUI.update({
      contactPage: 'contact',
      verificationType: null,
    });
  }
};

// - password tab - //

export const resetPasswordStart = async () => {
  try {
    const { currentPassword } = $settingsProfile.value;
    await auth.signInWithEmailAndPassword($user.value.user.email, currentPassword);
    const response = await accruClient.users.updatePassword({
      data: { current_password: currentPassword },
    });
    if (response) {
      handleNotification(`Verification code has been sent to ${$user.value.user.email}`, { variant: 'success' });
      $settingsProfileUI.update({
        passwordPage: 'validation',
      });
    }
    return response;
  } catch (error) {
    let { message } = error;
    if (message?.includes('auth/user-not-found')) {
      message = 'Email not found';
    }
    if (message?.includes('auth/wrong-password')) {
      message = 'Incorrect password';
    }
    handleNotification(message, { variant: 'danger' });
  }
};

export const handleVerifyFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    $settingsProfile.update({ verificationCode: oldFormData });
  }
};

export const resetPasswordFinish = async () => {
  try {
    $settingsProfile.loadingStart();
    const { verificationCode, newPassword } = $settingsProfile.value;
    const response = await accruClient.users.verifyPassword({
      data: {
        new_password: newPassword,
        verification_code: verificationCode.join(''),
      },
    });
    if (response) {
      $settingsProfile.reset();
      $settingsProfileUI.reset();
      handleNotification('Your password has been updated!', { variant: 'success' });
      $settingsProfile.update({ currentTab: 'password' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
    $settingsProfile.update({
      verificationCode: $settingsProfile.initialValue.verificationCode,
    });
  } finally {
    $settingsProfile.loadingEnd();
  }
};

export const validatePassword = () => {
  const { newPassword } = $settingsProfile.value;
  const { ...requirements } = $settingsProfile.value;
  requirements.reqLength = newPassword.length >= 12 && newPassword.length <= 36;
  requirements.uppercase = /[A-Z]/.test(newPassword);
  requirements.number = /\d/.test(newPassword);
  requirements.symbol = /[^A-Za-z0-9]/.test(newPassword);
  $settingsProfile.update({ passwordRequirements: requirements });
};
