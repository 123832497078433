import { SORT_ORDER } from 'accru-client';
import Signal from 'signals/Signal';

/**
 * @template T
 * @typedef {Object} SignalOutput
 * @property {T} value - The value returned by the Signal function.
 * @property {function} loadingStart - Sets the isLoading Property to true
 * @property {function} loadingEnd - Sets the isLoading Property to false
 * @property {function} peek - Gets the value of the singal by prop
 * @property {function} reset - Resets the value of the signal to the original value established at creation
 * @property {function} update - Updates the value of the signal without needing to spread props
 */

/**
 * @type {SignalOutput<InvoicesUI>}
 */
export const $invoice = Signal({});

/**
 * @typedef {Object} InvoicesUI
 * @property {string} selectedBulkAction - The label of the option.
 * @property {array} selectedInvoices - The value of the option.
 * @property {boolean} isBulkActionShow - Whether the option is disabled.
 * @property {boolean} isBulkActionsLoading - Whether the option is disabled.
 */

/**
 * @type {SignalOutput<InvoicesUI>}
 */
export const $invoicesUI = Signal({
  selectedBulkAction: 'Select',
  selectedItems: [],
  isBulkActionShow: false,
  isBulkActionsLoading: false,
});

/**
 * @typedef {Object} CustomerOption
 * @property {string} value - The value of the option.
 * @property {string} label - The label of the option.
 * @property {boolean} disabled - Whether the option is disabled.
 */

/**
 * @typedef {Object} InvoicesCustomerList
 * @property {string} customerSearchTerm - The search term for the customer.
 * @property {CustomerOption[]} customerOptions - The list of customer options.
 */

/**
 * @type {SignalOutput<InvoicesCustomerList>}
 */
export const $invoicesCustomerList = Signal({
  customerSearchTerm: '',
  customerOptions: [{
    value: 'all',
    label: 'All',
    disabled: false,
  }],
});

/**
 * @typedef {Object} SortingOption
 * @property {string} field - The field to sort by.
 * @property {string} order - The order of sorting ('ASC' or 'DESC').
 */

/**
 * @typedef {Object} InvoicesFilter
 * @property {string} status - The status filter.
 * @property {string|null} organizationCustomerId - The organization customer ID filter.
 * @property {boolean} isOverdue - The overdue filter.
 * @property {number} page - Current Page.
 * @property {number} take - Current take.
 * @property {string} startDate - Start Date.
 * @property {string} endDate - End Date.
 * @property {SortingOption[]} sorting - The sorting options.
 */

/**
 * @type {SignalOutput<InvoicesFilter>}
 */
export const $invoicesFilter = Signal({
  status: 'all',
  organizationCustomerId: null,
  isOverdue: undefined,
  page: 1,
  take: 20,
  sortKey: 'latest_acct_provider_status',
  sortDirection: SORT_ORDER.ASC,
  startDate: undefined,
  endDate: undefined,
});

/**
 * @typedef {Object} InvoicesCustomerOptions
 * @property {string} searchTerm - The search term for the customer.
 * @property {CustomerOption[]} options - The list of customer options.
 */

/**
 * @type {SignalOutput<InvoicesCustomerOptions>}
 */
export const $invoicesCustomerOptions = Signal({
  searchTerm: '',
  options: [],
});

export const $invoices = Signal({});

export const invoicesSignals = [
  $invoice,
  $invoices,
  $invoicesUI,
  $invoicesCustomerList,
  $invoicesFilter,
  $invoicesCustomerOptions,
];
