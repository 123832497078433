import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import Signal from 'signals/Signal';
import { history } from 'utils/history';
import { handleSelectCustomer, handleUpdateCustomer } from 'components/views/Customers/_helpers/Customers.helpers';
import { $customerDetail } from 'components/views/Customers/_helpers/Customers.signals';
import { handleSelectVendor, handleUpdateVendor } from 'components/views/Vendors/_helpers/Vendors.helpers';
import { $vendorDetail } from 'components/views/Vendors/_helpers/Vendors.signals';
import $user from 'signals/User.signals';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';
import { BILL_STATUS, INVOICE_STATUS, SORT_ORDER } from 'accru-client';

export const $sidePanelBillsDetails = Signal({
  bills: null,
});

export const $sidePanelSettingsDetails = Signal({ isEditing: false, dataLockedModalOpen: false });

export const $sidePanelSettingsContacts = Signal({
  contacts: [],
  isModalOpen: false,
  isEditingContact: null,
});

export const $sidePanelFormDataDetails = Signal({
  email: '',
  phone_number: '',
  name: '',
  isPrimary: false,
});

export const $sidePanelFormDataContacts = Signal({
  name: '',
  email: '',
  phone: '',
  enableReminders: '',
});

export const $selectedContact = Signal({});

export const fetchAndSetSidePanelBills = async ({ type }) => {
  try {
    $sidePanelBillsDetails.reset();
    $sidePanelBillsDetails.loadingStart();
    const entity = type === 'customer'
      ? $customerDetail.value
      : $vendorDetail.value;

    if (!entity?.id) throw new Error('No customer/vendor selected.');

    if (type === 'customer') {
      const data = await accruClient.invoices.get({
        organizationId: $user?.value?.currentOrganization?.id,
        organizationCustomerId: entity.id,

        latestAcctProviderStatus: INVOICE_STATUS.OPEN,

        sorting: [{
          field: 'invoice_date',
          order: SORT_ORDER.DESC,
        }],
      });

      $sidePanelBillsDetails.update({ bills: data.items });
    } else if (type === 'vendor') {
      const data = await accruClient.bills.get({
        organizationId: $user?.value?.currentOrganization?.id,
        organizationVendorId: entity.id,

        latestAcctProviderStatus: BILL_STATUS.OPEN,

        sorting: [{
          field: 'bill_date',
          order: SORT_ORDER.DESC,
        }],
      });

      $sidePanelBillsDetails.update({ bills: data.items });
    } else {
      throw new Error('Invalid entity type.');
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelBillsDetails.loadingEnd();
  }
};

export const deleteContactVendor = async ({ organizationVendorId, organizationVendorContactId }) => {
  try {
    const response = await accruClient.contacts.deleteVendorContact({
      organizationId: $user.value.currentOrganization.id,
      organizationVendorId,
      organizationVendorContactId,
    });
    if (response) {
      handleNotification('Contact deleted!', { variant: 'success' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const deleteContactCustomer = async ({ organizationCustomerId, organizationCustomerContactId }) => {
  try {
    const response = await accruClient.contacts.deleteCustomerContact({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId,
      organizationCustomerContactId,
    });
    if (response) {
      handleNotification('Contact deleted!', { variant: 'success' });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const getContacts = async ({ organizationVendorId }) => {
  try {
    const response = await accruClient.contacts.getVendorContacts({
      organizationId: $user.value.currentOrganization.id,
      organizationVendorId,
    });
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const getCustomerContacts = async ({ organizationCustomerId }) => {
  try {
    const response = await accruClient.contacts.getCustomerContacts({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId,
    });
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const handleSubmitDetails = async ({ type }) => {
  try {
    $sidePanelSettingsDetails.loadingStart();
    const formData = $sidePanelFormDataDetails.value;
    const { name, email, phone_number } = formData;

    const organization = type === 'customer'
      ? $customerDetail.value
      : $vendorDetail.value;
    if (type === 'customer') {
      const res = await handleUpdateCustomer({
        organizationCustomerId: organization.id,
        organizationId: $user?.value?.currentOrganization?.id,
        data: {
          name,
          email,
          phone_number: phone_number ? formatPhoneNumForApi(phone_number) : null,
          is_active: true,
        },
      });
      if (res) {
        $customerDetail.update({
          email: res.email,
          name: res.name,
          phone_number: res.phone_number || null,
        });
      }
    } else {
      await handleUpdateVendor({
        organizationVendorId: organization.id,
        data: {
          name,
          email,
          phone_number: phone_number ? formatPhoneNumForApi(phone_number) : null,
          is_active: true,
        },
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsDetails.reset();
  }
};

export const handleCloseModal = () => {
  $sidePanelFormDataContacts.reset();
  $sidePanelSettingsContacts.reset();
};

export const handleCreateContact = async ({ type }) => {
  try {
    $sidePanelFormDataContacts.loadingStart();
    const data = $sidePanelFormDataContacts.value;

    if (type === 'customer') {
      await accruClient.contacts.createCustomerContact({
        organizationId: $user.value.currentOrganization.id,
        organizationCustomerId: $customerDetail.value.id,
        data: {
          email: data.email,
          is_default: false,
          name: data.name,
          send_invoice_reminders: false,
          phone_number: data.phone_number ? formatPhoneNumForApi(data.phone_number) : null,
        },
      });
      handleSelectCustomer({ customer: $customerDetail.value });
    }

    if (type === 'vendor') {
      await accruClient.contacts.createVendorContact({
        organizationId: $user.value.currentOrganization.id,
        organizationVendorId: $vendorDetail.value.id,
        data: {
          email: data.email,
          is_default: false,
          name: data.name,
          phone_number: data.phone_number ? formatPhoneNumForApi(data.phone_number) : null,
        },
      });
      handleSelectVendor({ vendor: $vendorDetail.value });
    }
    handleCloseModal();
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelFormDataContacts.loadingEnd();
  }
};

export const handleUpdateClick = (contact) => {
  $sidePanelSettingsContacts.update({ isModalOpen: true, isEditingContact: true });
  $sidePanelFormDataContacts.update(contact);
  $selectedContact.update(contact);
};

export const handleEditEntityDetailsClick = ({ type }) => {
  const entity = type === 'customer'
    ? $customerDetail.value
    : $vendorDetail.value;

  if (!entity) return;

  if (entity.connection?.is_connected && entity.conn_locked_data_at) {
    $sidePanelSettingsDetails.update({ isEditing: false });
    $sidePanelSettingsDetails.update({ dataLockedModalOpen: true });
  } else {
    $sidePanelSettingsDetails.update({ isEditing: !$sidePanelSettingsDetails.value.isEditing });
  }
};

export const handleDeleteContact = async ({ type, contact }) => {
  try {
    $sidePanelFormDataContacts.loadingStart();
    if (type === 'customer') {
      await deleteContactCustomer({
        organizationCustomerId: $customerDetail.value.id,
        organizationCustomerContactId: contact.id,
      });
      handleSelectCustomer({ customer: $customerDetail.value });
    }

    if (type === 'vendor') {
      await deleteContactVendor({
        organizationVendorId: $vendorDetail.value.id,
        organizationVendorContactId: contact.id,
      });
      handleSelectVendor({ vendor: $vendorDetail.value });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelFormDataContacts.loadingEnd();
  }
};

export const handleUpdateContact = async ({ type }) => {
  try {
    $sidePanelFormDataContacts.loadingStart();

    const phoneNumber = $sidePanelFormDataContacts.value.phone_number
      ? formatPhoneNumForApi($sidePanelFormDataContacts.value.phone_number)
      : null;

    const newData = {
      is_default: $sidePanelFormDataContacts.value.is_default,
      phone_number: phoneNumber,
      name: $sidePanelFormDataContacts.value.name,
      email: $sidePanelFormDataContacts.value.email,
      send_invoice_reminders: false,
    };

    if (type === 'customer') {
      await accruClient.contacts.updateCustomerContact({
        organizationId: $user.value.currentOrganization.id,
        organizationCustomerId: $customerDetail.value.id,
        organizationCustomerContactId: $sidePanelFormDataContacts.value.id,
        data: newData,
      });

      await handleSelectCustomer({ customer: $customerDetail.value });
    }

    if (type === 'vendor') {
      delete newData.send_invoice_reminders;
      await accruClient.contacts.updateVendorContact({
        organizationId: $user.value.currentOrganization.id,
        organizationVendorId: $vendorDetail.value.id,
        organizationVendorContactId: $sidePanelFormDataContacts.value.id,
        data: newData,
      });

      await handleSelectVendor({ vendor: $vendorDetail.value });
    }
    handleCloseModal();
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelFormDataContacts.loadingEnd();
  }
};

export const handleClickBillRow = ({ bill, type }) => {
  history.push(`/${type === 'vendor' ? 'bills' : 'invoices'}/${bill.id}`, { bill, from: history.location });
};
