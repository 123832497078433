import { Col, Row } from 'react-bootstrap';
import Loadable from 'components/global/Loadable';
import $user from 'signals/User.signals';
import $selectAdminOrganizationDetail from 'components/views/SelectAdminOrganization/SelectAdminOrganization.signals';
import SelectAdminOrganizationCard from 'components/views/SelectAdminOrganization/_components/SelectAdminOrganizationCard';
import SelectAdminOrganizationSearch from 'components/views/SelectAdminOrganization/_components/SelectAdminOrganizationSearch';
import { fetchAndSetAdminOrganizations, handleSelectAdminOrganizationListClick, handleSelectAdminOrganizationModalClose } from 'components/views/SelectAdminOrganization/SelectAdminOrganization.helpers';
import useEffectAsync from 'utils/useEffectAsync';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import useCleanup from 'hooks/useCleanup';

const SelectAdminOrganizationModal = () => {
  useEffectAsync(fetchAndSetAdminOrganizations, [$selectAdminOrganizationDetail.value.search], 400);
  useCleanup($selectAdminOrganizationDetail);

  return (
    <SimpleModal
      isShow={$selectAdminOrganizationDetail.value.isModalOpen}
      title="Impersonate Organization"
      onClose={() => handleSelectAdminOrganizationModalClose()}
      isHideSave
    >
      <Row>
        <SelectAdminOrganizationSearch />
      </Row>
      <Row className="mt-16">
        <Col className="overflow-scroll-y">
          <Loadable template="component" signal={$selectAdminOrganizationDetail}>
            {$selectAdminOrganizationDetail.value.items.map((organization) => (
              <SelectAdminOrganizationCard key={organization.id} organization={organization} onSelect={() => handleSelectAdminOrganizationListClick(organization)} isSelected={$user.value.currentOrganization?.id === organization.id} />
            ))}
          </Loadable>
        </Col>
      </Row>
    </SimpleModal>
  );
};

export default SelectAdminOrganizationModal;
