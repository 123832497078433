import DateRangePicker from 'components/global/Inputs/DateRangePicker/DateRangePicker';
import SimpleSelect from 'components/global/Inputs/SimpleSelect';
import { $billsFilter, $billsVendorOptions } from 'components/views/Bills/Bills.signals';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { statusOptions } from 'utils/selectArrays';

const BillsFilters = ({ organizationVendorId }) => {
  useEffect(() => {
    $billsFilter.update({ organizationVendorId });
  }, [organizationVendorId]);

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Vendor</Form.Label>
          <SimpleSelect
            value={$billsFilter.value.organizationVendorId}
            options={$billsVendorOptions.value.options}
            onChange={(selectedOption) => {
              $billsFilter.update({ page: 1 });
              if (!selectedOption?.value) {
                $billsFilter.update({ organizationVendorId: null });
              } else {
                $billsFilter.update({ organizationVendorId: selectedOption.value });
              }
            }}
            isClearable
            isLoading={$billsVendorOptions.value.isLoading}
            isDisabled={$billsVendorOptions.value.isLoading}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Label>Status</Form.Label>
        <SimpleSelect
          value={$billsFilter.value.status}
          options={statusOptions}
          onChange={(selectedOption) => {
            $billsFilter.update({ page: 1 });
            if (!selectedOption?.value) {
              return $billsFilter.update({ status: null });
            }
            $billsFilter.update({ status: selectedOption.value });
          }}
        />
      </Col>
      <Col className="pt-16 pt-md-0">
        <Form>
          <Form.Group>
            <Form.Label>Start Date - End Date</Form.Label>
            <DateRangePicker
              onChange={({ value, name }) => {
                $billsFilter.update({ page: 1, [name]: value });
              }}
              variant="form-control-border"
              startName="startDate"
              endName="endDate"
              startValue={$billsFilter.value.startDate}
              endValue={$billsFilter.value.endDate}
            />
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};
export default BillsFilters;
