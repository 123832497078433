import { Card, Row, Col, Image, Placeholder } from 'react-bootstrap';
import Money from 'components/global/Money';
import seeBalanceIcon from 'images/seeBalanceIcon.svg';
import folder from 'images/folder.svg';
import creditCard from 'images/creditCard.svg';
import { handleOpenStatement } from 'components/views/Vendors/_helpers/Vendors.helpers';
import { $syncing } from 'signals/Global.signals';

const VendorCard = ({ item, onSelect, handleClickBills, handleClickProjects, active }) => (
  <Card onClick={() => onSelect(item)} className={`border bg-none rounded-4 w-100 p-8 py-md-24 mb-16 mb-md-24 cursor-pointer ${active ? 'border-2 border-info' : 'row-hover-effect'}`}>
    <Row className="align-items-center m-0">
      <Col xs={2} sm={2} className="d-flex align-items-center p-0">
        <div className="rounded-circle border border-primary position-relative d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px' }}>
          <h2 className="fw-700 mb-0 text-primary p-0">{item?.name?.charAt(0).toUpperCase()}</h2>
        </div>
      </Col>
      <Col xs={10} sm={4} className="p-0 pe-16">
        <h3 className="ms-8 ms-md-0 mb-0">{item.name}</h3>
        <small>{item.primary_contact_name}</small>
      </Col>
      <Col xs={7} sm={3} className="ps-24 p-md-0 mt-8 p-0">
        <div className="small fw-800">Open Balance</div>
        {$syncing.value.isSyncing ? (
          <Placeholder as="div" animation="glow" className="mb-0">
            <Placeholder xs={12} as="p" className="text-loadable" />
          </Placeholder>
        ) : (
          <div className="lead fw-800 text-primary"><Money amount={item.latest_acct_provider_balance} hide={item.latest_acct_provider_balance === null} /></div>
        )}
      </Col>
      <Col xs={5} sm={3} className="mt-8 p-0 ps-16">
        <div className="d-flex justify-content-between align-items-center">
          <Image height={26} src={seeBalanceIcon} onClick={() => handleOpenStatement({ vendorId: item.id })} />
          <Image height={22} src={folder} onClick={handleClickProjects} />
          <Image height={25} src={creditCard} onClick={handleClickBills} />
        </div>
      </Col>
    </Row>
  </Card>
);

export default VendorCard;
