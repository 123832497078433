import { Card, Row, Image, Col, Button, Dropdown } from 'react-bootstrap';
import SidePanelContactsModal from 'components/global/SidePanel/_components/SidePanelContactsModal';
import threeDotsHorizontal from 'images/threeDotsHorizontal.svg';
import addMore from 'images/addMore.svg';
import formatPhoneDisplay from 'utils/formatPhoneDisplay';
import { $customerDetail } from 'components/views/Customers/_helpers/Customers.signals';
import { $vendorDetail } from 'components/views/Vendors/_helpers/Vendors.signals';
import {
  $sidePanelSettingsContacts,
  handleUpdateClick,
  handleDeleteContact,
} from './SidePanel.helpers';

const SidePanelContact = ({ type }) => {
  const organization = type === 'vendor'
    ? $vendorDetail.value
    : $customerDetail.value;
  const { contacts } = organization;

  return (
    <>
      <SidePanelContactsModal type={type} />
      <Card className="bg-gray-50">
        <Row
          className="d-none d-lg-flex mb-8 pt-32 pb-8 ps-56 m-0"
          style={{ borderBottom: '1px solid #EDEDED' }}
        >
          <Col sm={11} className="p-0 text-primary">
            <Row className="m-0 base fw-800">
              <Col sm={4} className="p-0 text-primary">
                <div>Name</div>
              </Col>
              <Col sm={4} className="p-0 text-primary">
                <div>Email</div>
              </Col>
              <Col sm={4} className="p-0 text-primary">
                <div>Phone</div>
              </Col>
            </Row>
          </Col>
          <Col sm={1} className="p-0 text-primary" />
        </Row>
        {contacts && contacts?.map((contact) => (
          <div key={contact.id}>
            <Row
              className="base p-24 m-0 d-none d-lg-flex ps-56 pe-0"
              style={{ borderBottom: '1px solid #EDEDED' }}
            >
              <Col sm={11} className="p-0 text-primary">
                <Row className="m-0">
                  <Col sm={4} className="p-0 text-primary">
                    <h5 className="fw-400 pe-8">{contact.name}</h5>
                  </Col>
                  <Col sm={4} className="p-0 text-primary">
                    <div className="base text-dark fw-400 pe-8">
                      {contact.email}
                    </div>
                  </Col>
                  <Col sm={4} className="p-0 text-primary">
                    <div className="base text-dark pe-8">
                      {formatPhoneDisplay(contact.phone_number) || 'N/A'}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={1} className="p-0 text-primary">
                <Dropdown>
                  <Button
                    variant="eye"
                    className="p-0 m-0 text-secondary h-100"
                  >
                    <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle p-0 m-0 h-100">
                      <Image height={16} src={threeDotsHorizontal} />
                    </Dropdown.Toggle>
                  </Button>
                  <div className="float-right">
                    <Dropdown.Menu className="p-0">
                      <Dropdown.Item
                        onClick={() => handleUpdateClick(contact)}
                        className="rounded"
                      >
                        Update
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleDeleteContact({ type, contact })}
                        className="rounded"
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                </Dropdown>
              </Col>
            </Row>
            <Row
              className="d-lg-none align-items-center px-24 py-16"
              style={{ borderBottom: '1px solid #EDEDED' }}
            >
              <Col>
                <h5 className="fw-400 mb-0">{contact.name}</h5>
                <div className="font-base fw-400">{contact.email}</div>
              </Col>
              <Col className="d-flex justify-content-between">
                <div>
                  <div>{contact.phone}</div>
                  <div className="text-primary">{contact.role}</div>
                </div>
                <div className="align-items-center">
                  <Image height={16} src={threeDotsHorizontal} />
                </div>
              </Col>
            </Row>
          </div>
        ))}
        <Row className="d-flex justify-content-start my-24 my-md-48 mx-0">
          <Col className="p-0">
            <div
              className="d-flex ms-16 ms-md-40 cursor-pointer"
              tabIndex={0}
              role="button"
              onClick={() => $sidePanelSettingsContacts.update({ isModalOpen: true, isEditingContact: false })}
            >
              <Image className="text-start" height={18} src={addMore} />
              <div className="ms-8">Add {contacts?.length ? 'more' : 'contact'}</div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SidePanelContact;
